import { AfterViewInit, Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { AppLoader } from '@proman/services/app-loader.service';
import { LanguageConfigService } from '@proman/services/language-config.service';
import { ActivatedRoute } from '@angular/router';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { Entity } from '@proman/services/entity.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { UI_DARK_MODE, UiPreferencesService } from '@proman/services/ui-preferences.service';
import { OnlineStatusService } from '@proman/services/online-status.service';
import { Store } from '@ngrx/store';
import { getCurrUserUiPrefs } from '@proman/store/curr-user/curr-user.selectors';
import { delay, isTouchDevice } from '@proman/utils';

@Component({
    selector: 'pm-proman',
    template: `
        <pm-layout fxLayout="column"
                   fxFlex="auto"
                   [ngClass]="{ 'dark-mode': !!isDarkMode }"
        >
                <pm-header></pm-header>
                <pm-toolbar></pm-toolbar>
                <router-outlet></router-outlet>
        </pm-layout>
    `
})

export class MainComponent implements OnInit, AfterViewInit {
    @Input() user: CurrUser;
    isDarkMode: boolean;

    constructor(
        private AppLoader: AppLoader,
        private LanguageConfig: LanguageConfigService,
        private route: ActivatedRoute,
        private Dialog: Dialog,
        private Entity: Entity,
        private Render2: Renderer2,
        private UiPrefs: UiPreferencesService,
        private OnlineStatus: OnlineStatusService,
        @Inject(Store) private store: Store,
    ) {
        this.user = this.route.snapshot.data['user'];
    }

    ngOnInit() {
        this.AppLoader.remove();

        if (this.isDarkMode) {
            this.Render2.addClass(document.querySelector('html'), 'dark-mode')
        }

        this.LanguageConfig.set(this.user.language);

        let _isTouchDevice = isTouchDevice();

        if (_isTouchDevice) this.Render2.addClass(document.querySelector('html'), 'MobileDevice');

    }

    ngAfterViewInit(): void {
        delay(100).then(() => {
            this.store.select(getCurrUserUiPrefs)
                .subscribe((uiPrefs) => {
                    this.isDarkMode = uiPrefs[UI_DARK_MODE];
                });
        });
    }

}
