import { ErrorHandler, Injectable, InjectionToken, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular';
// import * as SentryAngular from '@sentry/angular';
import { Event as SentryEvent } from '@sentry/browser';
// import { CONFIG } from '../config';
import { isLocalhost } from '@proman/utils';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { CurrUser } from '@proman/interfaces/object-interfaces';
// import { Router } from '@angular/router';
// import { APP_INITIALIZER } from '@angular/core';

declare interface SentryModuleConfig {
    project: 'frontend'|'pos'|'shop'|'health'|'smarttag'|'master'|'kitchen'
}

const SentryConfigService = new InjectionToken<SentryModuleConfig>('SentryConfigService')

const chunkLoadFailErrorRegExp = new RegExp('/Loading chunk [\\d]+ failed/');
const chunkFetchFailErrorRegExp = new RegExp('/Failed to fetch dynamically imported module: http.*');

Sentry.init({
    dsn: 'https://5249f0f475b543879158d130145f19a2@sentry.io/176946',
    beforeSend(event: SentryEvent, hint?: any): Promise<SentryEvent | null> | SentryEvent | null {
        const error = hint.originalException;
        const httpErrorsStatusesToCatch = [0, 400, 401, 403, 404, 409, 500, 502, 504];

        if (window.location.host.includes('localhost')) return;

        if (chunkLoadFailErrorRegExp.test(error?.toString())) {
            window.location.reload();
            return null;

        }

        if (chunkFetchFailErrorRegExp.test(error?.toString())) {
            window.location.reload();
            return null;
        }

        if (httpErrorsStatusesToCatch.includes(error?.status)) return null;
        if (error?.toString().includes('Uncaught (in promise)')) return null;

        return event;
    },
    tracesSampleRate: isLocalhost() ? 1.0 : 0.3,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin],
    })],
});

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
    currUser: CurrUser;
    store: Store;

    constructor(
      private sentryConfig: SentryModuleConfig,
      private inj: Injector,
      ) {
        setTimeout(() => {
            this.store = inj.get(Store);
            this.store.select(getCurrUser).subscribe((value) => this.currUser = value);
        })
    }
    handleError(error: any): any {
        // lazy load module fail
        if (
            chunkLoadFailErrorRegExp.test(error) ||
          chunkFetchFailErrorRegExp.test(error) ||
            error.rejection && error.rejection.loadChunkError
        ) {
            window.location.reload();
            return null;
        }

        console.warn(`Error in project "${this.sentryConfig.project}, url: ${window.location.href}"`);

        if (this.currUser && this.currUser.person) {
            Sentry.setUser({ id: this.currUser.person.id.toString(), email: this.currUser.person.email, username: this.currUser.person.name });
        }
        Sentry.captureException(error.originalError || error);

        throw error;
    }
}

@NgModule({
    providers: [
        { provide: ErrorHandler, useClass: SentryErrorHandler, deps: [SentryConfigService, Injector] },
    ]
})

export class SentryModule {

    static forRoot(config: SentryModuleConfig): ModuleWithProviders<SentryModule> {
        return {
            ngModule: SentryModule,
            providers: [
                {
                    provide: SentryConfigService,
                    useValue: config
                }
            ]
        }
    }

}
