import { Component, Inject, ElementRef, ViewChild, OnDestroy, AfterViewInit, Renderer2, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from 'ngx-flexible-layout';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import moment from 'moment';
import $ from 'jquery';
import { MenuService } from '../services/menu.service';
import { UI_COMPACT_MENU } from '@proman/services/ui-preferences.service';
import { ObservablesService } from '@proman/services/observables.service';
import { PromanStateService } from '../services/proman-state.service';
import { delay } from '@proman/utils';
import { getCurrUserUiPrefs } from '@proman/store/curr-user/curr-user.selectors';
import { Store } from '@ngrx/store';
import { LanguageConfigService } from '@proman/services/language-config.service';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';

const SIDENAV_WIDTH = '170px';
const COMPACT_SIDENAV_WIDTH = '100px';

@Component({
    selector: 'pm-layout',
    template: `
        <div #element
             class="Layout"
             [ngClass]="{ 'mobile': isMobile, 'compact-layout': isCompactMenu }">
            <mat-sidenav-container (backdropClick)="closeSidenav()"
                                   class="MenuSidenav">
                <mat-sidenav #sidenav
                             [mode]="sidebarMode"
                             [opened]="isExpanded"
                             (close)="closeSidenav()"
                             [disableClose]="true"
                             fxLayout="column"
                             class="OverflowAuto"
                             style="padding-bottom: 40px;"  >
                    <div fxLayout="column">
                        <pm-account></pm-account>
                        <pm-menu></pm-menu>
                    </div>
                    <pm-tool-btns></pm-tool-btns>
                </mat-sidenav>
                <div class="MainContent">
                    <ng-content></ng-content>
                </div>
            </mat-sidenav-container>
            <div class="Copyrights">
                &copy; SmartOn {{ year }}
            </div>
            @if (sidebarMode === 'over') {
                <div class="SidebarTrigger" (mouseenter)="openSidenav()"></div>
            }
        </div>
        <pm-overlay></pm-overlay>
        <pm-docs></pm-docs>
    `,
    styles: `
        .SidebarTrigger {
            position: absolute;
            height: 100%;
            width: 15px;
            top: 0;
            left: 0;
            z-index: 3;
        }

    `
})

export class LayoutComponent implements AfterViewInit, OnDestroy {
    @ViewChild('element', { static: true }) element: ElementRef;
    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

    year = moment().format('YYYY');
    watcher: Subscription;
    sidebarMode: MatDrawerMode = 'side';
    isExpanded: boolean;
    isCompactMenu: boolean = false;
    isMobile: boolean;
    timeZone: any;
    drawerEl: any;
    sidenavEl: any;

    constructor(
        private media: MediaObserver,
        private Menu: MenuService,
        private Location: Location,
        private Render2: Renderer2,
        private Observables: ObservablesService,
        private PromanState: PromanStateService,
        private LangConfig: LanguageConfigService,
        @Inject(Store) private store: Store,
    ) {

        this.watcher = media.asObservable().subscribe((change: MediaChange[]) => {

            if (change.some((change: MediaChange) => change.mqAlias === 'lt-md')) {
                this.sidebarMode = 'over';
                this.isMobile = true;
                this.isExpanded = false;

            } else {
                this.sidebarMode = 'side';
                this.isMobile = false;
                this.isExpanded = true;
            }

            this.fixMatBug();
        });

        window.onresize = this.handleWindowResize;
    }

    ngAfterViewInit() {
        this.fixMatBug();

        this.sidenavEl = this.element.nativeElement.querySelector('.mat-sidenav');
        this.drawerEl = this.element.nativeElement.querySelector('.mat-drawer-content');

        if (this.Location.path() === '' || this.Location.path() === '/') {
            this.Menu.init();
            this.PromanState.go();

        }

        this.toggleSidenavDimensions();

        this.Observables.layout.subject.subscribe(() => this.toggleMenu());

        delay(500).then(() => {
            this.store.select(getCurrUserUiPrefs)
                .subscribe((uiPrefs) => {
                    this.isCompactMenu = uiPrefs[UI_COMPACT_MENU]
                    this.toggleSidenavDimensions();
                });
        });
    }

    ngOnDestroy() {
        this.watcher.unsubscribe();
    }

    async setSidenavDimensions(dimension: string) {

        if ((this.sidenavEl && this.drawerEl)) {
            this.sidenavEl.style.width = dimension;
            this.sidenavEl.style.minWidth = dimension;
            this.drawerEl.style.marginLeft = this.isMobile ? 0 : dimension;

        } else {
            await setTimeout(() => this.setSidenavDimensions(dimension), 200);

        }

    }

    toggleSidenavDimensions() {
        this.setSidenavDimensions(this.isCompactMenu ? COMPACT_SIDENAV_WIDTH : SIDENAV_WIDTH);
    }

    closeSidenav() {
        console.log('closeSidenav');
        this.isExpanded = false;
    }

    openSidenav() {
        console.log('openSidenav');
        this.isExpanded = true;
        this.sidenav.open();
    }

    toggleMenu = () => {

        if (this.isMobile) {
            this.isExpanded = !this.isExpanded;

        } else {

            this.toggleSidenavDimensions();

            // trigger window resize for services/directives that
            // are listening for width/height changes after
            // sidebar animation is completed
            setTimeout(() => $(window).trigger('resize'), 400);
        }

    };

    triggerToggleMenu() {
        this.Observables.layoutToggle();
    }

    fixMatBug = () => {
        if (this.element) {
            const method = this.isMobile ? 'removeClass' : 'addClass';
            this.Render2[method](this.element.nativeElement, 'fixBug'); // fix bug of hidden menus on desktops after relogging

        }
    };

    handleWindowResize = () => {
        this.toggleSidenavDimensions();
        this.fixMatBug();
    };

}
