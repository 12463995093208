import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        let key: string = params.key;

        key = key.split('_').join(' ');

        key = key.charAt(0).toUpperCase() + key.slice(1);

        return key;
    }
}