import { NgModule } from '@angular/core';
import { MenuComponent } from '@frontend/shared/components/menu.component';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToolbarComponent } from '@frontend/shared/components/toolbar.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { InputsModule } from '../../inputs/inputs.module';
import { HeaderComponent } from '@frontend/shared/components/header.component';
import { SearchComponent } from '@frontend/shared/components/search.component';
import { AccountComponent } from '@frontend/shared/components/account.component';
import { ToolBtnsComponent } from '@frontend/shared/components/tool-btns.component';
import { LayoutComponent } from '@frontend/shared/components/layout.component';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { UrlReadDialogComponent } from '@frontend/shared/components/url-read-dialog.component';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';
import { PromanCommonComponentsModule } from '@proman/common-components/proman-common-components.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { SharedDialogsModule } from '../../shared-dialogs/shared-dialogs.module';
import { FrontendPipesModule } from '@frontend/shared/pipes/frontend-pipes.module';
import { DocsIdDirective } from '../directives/docs-id.directive';
import { SharedDirectivesModule } from '@proman/shared/directives/shared-directives.module';
import { Fa6Module } from '@proman/fa/fa6.module';
import { PromanColorComponent } from "@proman/color/proman-color.component";
import { ToolbarActionsDialogComponent } from '@frontend/shared/components/toolbar-actions-dialog.component';
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { FrontendListManagerModule } from "@frontend/list-manager/frontend-list-manager.module";
import { PromanAutocompleteComponent } from '@proman/autocomplete/proman-autocomplete.component';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        RouterModule,
        InputsModule,
        SharedDirectivesModule,
        PromanCommonComponentsModule,
        SharedComponentsModule,
        SharedDialogsModule,
        MatLegacyButtonModule,
        MatLegacyChipsModule,
        MatSidenavModule,
        MatToolbarModule,
        MatLegacyMenuModule,
        MatLegacyDialogModule,
        FlexLayoutModule,
        Fa6Module,
        FrontendPipesModule,
        PromanColorComponent,
        FrontendListManagerModule,
        PromanAutocompleteComponent,
    ],
    declarations: [
        MenuComponent,
        ToolbarComponent,
        HeaderComponent,
        SearchComponent,
        AccountComponent,
        ToolBtnsComponent,
        LayoutComponent,
        UrlReadDialogComponent,
        DocsIdDirective,
        ToolbarActionsDialogComponent,
    ],
    providers: [
        Dialog,
    ],
    exports: [
        MenuComponent,
        ToolbarComponent,
        HeaderComponent,
        SearchComponent,
        AccountComponent,
        ToolBtnsComponent,
        LayoutComponent,
        UrlReadDialogComponent,
        ToolbarActionsDialogComponent,
    ]
})

export class MenuModule {}
